<template>
  <div class="MySubscriber" v-if="user">
    <div class="MySubscriberUser" dpadding>
      <UserAvatar :user="user" size="82px" />
      <Spacer num=".7" />
      <p class="MySubscriberName" strong>@{{ user.user }}</p>
    </div>
    <Spacer num="1" />
    <div class="MySubscriberMenu">
      <LargeButton :label="$locale['subscribe_from']" icon="false" :rightDesc="date_locale(subscription.date)" prevent />
      <LargeButton :label="$locale['last_renewal']" icon="false" :rightDesc="date_locale(subscription.activated)" prevent />
      <Spacer style="background:var(--body-bg);" />
      <LargeButton :label="$locale['send_message']" icon="messages" @click="sendMessage" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["user", "modal", "subscription"],
  methods: {
    sendMessage: function() {
      this.modal.close(() => {
        this.setQuery({ messages: this.user._id });
      });
    },
  },
};
</script>

<style lang="scss">
.MySubscriber {
  .MySubscriberUser {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .MySubscriberName {
    font-size: 120%;
  }
}
</style>
